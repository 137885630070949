<template>
  <g
    :class="{ notPrintable: !!node.isNotPrintable }"
    :data-id="node.id"
    :data-definition-id="node.definitionId"
    :data-type="node.type"
  >
    <template v-for="child in node.components">
      <component
        :is="getComponent(child.type)"
        v-show="isVisible(child)"
        :key="child.id"
        :class="{ notPrintable: !!child.isNotPrintable }"
        :node="child"
        :renderingContext="renderingContext"
        :showAllPrintables="showAllPrintables"
        :transform="transform(child)"
        :data-id="child.id"
        :data-definition-id="child.definitionId"
        :data-type="child.type"
      >
      </component>
    </template>
  </g>
</template>
<script>
import { ComponentTypes } from 'piivo-poster-engine/src/constants';
import ComponentMixin from 'piivo-poster-engine/src/renderer/rendererComponents/sigComponentMixin.js';
import RenderTools from 'piivo-poster-engine/src/renderer/renderTools.ts';
import { getRendererComponentsManager } from 'piivo-poster-engine/src/services/rendererComponentsManager.ts';

export default {
  mixins: [ComponentMixin],

  data() {
    return {
      ComponentTypes,
    };
  },

  methods: {
    getComponent(type) {
      return getRendererComponentsManager().getComponent(type);
    },
    /**
     * Component visibility
     */
    isVisible(node) {
      return RenderTools.isVisible(node, this.renderingContext);
    },
    /**
     * Transformations definition applied to the component
     */
    transform(node) {
      const translate = `translate(${node.arrangeNodeCache(node, this.renderingContext).x},${
        node.arrangeNodeCache(node, this.renderingContext).y
      })`;

      const nodeScale = RenderTools.getActualValue(node, node.scale, this.renderingContext) || {
        x: 1,
        y: 1,
      };
      const scale = `scale(${RenderTools.getActualValue(
        node,
        nodeScale.x,
        this.renderingContext
      )},${RenderTools.getActualValue(node, nodeScale.y, this.renderingContext)})`;

      const rotate = `rotate(${node.angle || 0},${
        node.measureNodeCache(node, this.renderingContext).width / 2.0
      },${node.measureNodeCache(node, this.renderingContext).height / 2.0})`;

      return `${translate} ${scale} ${rotate}`;
    },
  },
};
</script>
