import { getResourcesService } from 'piivo-poster-engine/src/services/resourcesService';

import services from '../../../core/services';
import { IApplicationsService } from '../../../platform/services/types';
import { POSTER_APP_NAME } from '../constants';

/**
 * Handles poster resources
 */
export class PosterResourcesService {
  /**
   * Callback for POSter app mount
   */
  getTemplateResourceUrl(): string {
    const resourceUrl = services
      .getService<IApplicationsService>('applications')
      ?.getAppParameter<string>(POSTER_APP_NAME, 'resourceURL');
    if (typeof resourceUrl !== 'string') {
      throw new Error(`POSter "resourceUrl" not found in app parameters`);
    }
    const templateDirectory = services
      .getService<IApplicationsService>('applications')
      ?.getAppParameter<string>(POSTER_APP_NAME, 'templateDirectory');
    if (typeof templateDirectory !== 'string') {
      throw new Error(`POSter "templateDirectory" not found in app parameters`);
    }

    return getResourcesService().resolveAbsoluteUrl(templateDirectory, resourceUrl);
  }
}
