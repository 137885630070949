import store from '../../../core/store';
import { NAMESPACE as POSTER_NAMESPACE, SIGNAGES_ARE_SYNCHRONIZED } from '../store/modules/poster';
import { ISignagesSynchronization } from './types';

/**
 * The synchronisation delay in ms.
 */
const SYNC_DELAY_MS = 2_000;

export class SignagesSynchronization implements ISignagesSynchronization {
  timeoutSync: number | null = null;

  /**
   * @inheritdoc
   */
  startSynchronizationDelay(): void {
    store.commit(`${POSTER_NAMESPACE}/${SIGNAGES_ARE_SYNCHRONIZED}`, false);
    this.resetSynchronizationDelay();
    this.timeoutSync = window.setTimeout(() => {
      store.commit(`${POSTER_NAMESPACE}/${SIGNAGES_ARE_SYNCHRONIZED}`, true);
    }, SYNC_DELAY_MS);
  }

  /**
   * @inheritdoc
   */
  resetSynchronizationDelay(): void {
    if (this.timeoutSync) {
      clearTimeout(this.timeoutSync);
    }
  }

  /**
   * @inheritdoc
   */
  actionDuringSynchronization(cb: () => void, { once } = { once: false }): () => void {
    // If synchronization is already in process, execute cb
    if (store && store.state && store.state.templates && !store.state.templates.isSynchronized) {
      cb();
      if (once) {
        return () => {
          // Do nothing
        };
      }
    }
    const unsubscribe = store.subscribe((mutation) => {
      if (
        mutation.type === `${POSTER_NAMESPACE}/${SIGNAGES_ARE_SYNCHRONIZED}` &&
        mutation.payload === false
      ) {
        cb();
        if (once) {
          unsubscribe();
        }
      }
    });

    return unsubscribe;
  }

  /**
   * @inheritdoc
   */
  actionAfterSynchronization(
    cb: () => void,
    opts?: { once?: boolean; disableImmediate?: boolean }
  ): () => void {
    const mergedOpts = {
      once: false,
      disableImmediate: false,
      ...opts,
    };
    // If synchronization is already done execute cb
    if (
      !mergedOpts.disableImmediate &&
      store &&
      store.state &&
      store.state.poster &&
      store.state.poster.isSynchronized
    ) {
      cb();
      if (mergedOpts.once) {
        return () => {
          // Do nothing
        };
      }
    }

    const unsubscribe = store.subscribe((mutation) => {
      if (
        mutation.type === `${POSTER_NAMESPACE}/${SIGNAGES_ARE_SYNCHRONIZED}` &&
        mutation.payload === true
      ) {
        cb();
        if (mergedOpts.once) {
          unsubscribe();
        }
      }
    });

    return unsubscribe;
  }
}
