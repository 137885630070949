import Permissions from '../constants/permissions';

const routes = [
  {
    path: '/poster',
    name: '',
    component: () => import('../views/PosterHome'),
    children: [
      {
        path: 'dashboard',
        name: 'posterDashboard',
        component: () => import('../views/PosterDashboard'),
        meta: {
          permission: Permissions.DASHBOARD,
        },
      },
      {
        path: 'batch-creation',
        name: 'posterBatchCreation',
        component: () => import('../views/PosterBatchCreation'),
        meta: {
          permission: Permissions.QUICK,
        },
      },
      {
        path: 'creation',
        name: 'posterCreation',
        component: () => import('../views/PosterCreation'),
        meta: {
          permission: Permissions.QUICK,
        },
      },
      {
        path: 'list',
        name: 'posterDemands',
        component: () => import('../views/PosterDemands'),
        meta: {
          permission: Permissions.LOGS,
        },
      },
      {
        path: '',
        redirect: {
          name: 'posterDashboard',
        },
      },
    ],
  },
];

export default routes;
