import PiivoPosterEngine from 'piivo-poster-engine/src';
import { components } from 'piivo-poster-engine/src/components';
import { ComponentTypes } from 'piivo-poster-engine/src/constants';
import { getPosterEngineExtensionPoint } from 'piivo-poster-engine/src/extensionPoints';
import sigBarcode from 'piivo-poster-engine/src/renderer/rendererComponents/SigBarcode.vue';
import sigDatamatrix from 'piivo-poster-engine/src/renderer/rendererComponents/SigDatamatrix.vue';
import sigEllipse from 'piivo-poster-engine/src/renderer/rendererComponents/SigEllipse.vue';
import sigGenericBarcode from 'piivo-poster-engine/src/renderer/rendererComponents/SigGenericBarcode.vue';
import sigImage from 'piivo-poster-engine/src/renderer/rendererComponents/SigImage.vue';
import sigLine from 'piivo-poster-engine/src/renderer/rendererComponents/SigLine.vue';
import sigPath from 'piivo-poster-engine/src/renderer/rendererComponents/SigPath.vue';
import sigRectangle from 'piivo-poster-engine/src/renderer/rendererComponents/SigRectangle.vue';
import sigStepText from 'piivo-poster-engine/src/renderer/rendererComponents/SigStepText.vue';
import sigText from 'piivo-poster-engine/src/renderer/rendererComponents/SigText.vue';
import Vue, { DefineComponent } from 'vue';

import { coreExtension } from '../../../core/extensionPoints';
import sigComponent from './rendererComponents/SigComponent.vue';

/**
 * Installs the poster engine plugin
 *
 * @param useCache - if should use existing cache
 */
export function installEngine(useCache: boolean) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  Vue.use(PiivoPosterEngine, {
    proxy:
      process.env.NODE_ENV === 'development'
        ? (url: string) => `/_proxyTo?url=${encodeURIComponent(url)}`
        : null,
  });
  const engine = getPosterEngineExtensionPoint();

  if (!useCache) {
    engine.clearCachedData();
  }

  // #region Log engine events
  // Add logging handlers for all engine events
  function errorHandler({
    code,
    data: { message, ...restData },
  }: {
    code: string;
    data: { message: string; [x: string]: unknown };
  }) {
    const msg = `piivo-poster-engine error : ${code}\n${message}`;
    if (Object.keys(restData).length) {
      console.error(msg, restData);
    } else {
      console.error(msg);
    }
  }

  function warnHandler({
    data: { message, ...restData },
  }: {
    data: { message: string; [x: string]: unknown };
  }) {
    const msg = `piivo-poster-engine warning\n${message}`;
    if (Object.keys(restData).length) {
      console.warn(msg, restData);
    } else {
      console.warn(msg);
    }
  }

  coreExtension.eventBus.on('piivo-poster-engine/componentsManager/error', errorHandler);
  coreExtension.eventBus.on('piivo-poster-engine/fontsManager/error', errorHandler);
  coreExtension.eventBus.on('piivo-poster-engine/lesserEval/error', errorHandler);
  coreExtension.eventBus.on('piivo-poster-engine/componentFunction/error', errorHandler);
  coreExtension.eventBus.on('piivo-poster-engine/textComponentFunction/error', errorHandler);

  coreExtension.eventBus.on('piivo-poster-engine/lesserEval/warn', warnHandler);
  // #endregion

  // #region Vue 2.7 compat
  // Register components manually instead of auto registering via engine
  // for Vue 2.7 compatibility
  const rendererComponents = [
    { componentType: ComponentTypes.TEXT, component: sigText },
    { componentType: ComponentTypes.STEP_TEXT, component: sigStepText },
    { componentType: ComponentTypes.RECTANGLE, component: sigRectangle },
    { componentType: ComponentTypes.LINE, component: sigLine },
    { componentType: ComponentTypes.PATH, component: sigPath },
    { componentType: ComponentTypes.ELLIPSE, component: sigEllipse },
    { componentType: ComponentTypes.IMAGE, component: sigImage },
    { componentType: ComponentTypes.BARCODE, component: sigBarcode },
    { componentType: ComponentTypes.DATAMATRIX, component: sigDatamatrix },
    { componentType: ComponentTypes.STACK_LAYOUT, component: sigComponent }, // Register our Vue 2.7 version of SigComponent
    { componentType: ComponentTypes.WRAP_LAYOUT, component: sigComponent },
    { componentType: ComponentTypes.COMPONENT, component: sigComponent },
    { componentType: ComponentTypes.GENERIC_BARCODE, component: sigGenericBarcode },
  ];
  for (const rendererComponent of rendererComponents) {
    engine.rendererComponentsManager.registerComponent(
      rendererComponent.componentType,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
      rendererComponent.component as any
    );
  }

  for (const component of components as unknown as DefineComponent[]) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    Vue.component(component.name!, component);
  }
  // #endregion
}
