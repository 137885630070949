import Vue from 'vue';
import VueRouter from 'vue-router';
import { Store } from 'vuex';

import { coreExtension } from '../../../core/extensionPoints';
import services from '../../../core/services';
import {
  IApplicationsService,
  PiivoApplicationLifecycleService,
} from '../../../platform/services/types';
import { AttributeValues, Form } from '../../common/api/attributes';
import { AttributeTypes, DisplayMode } from '../../common/constants';
import { iterateForm, queryToFormValues } from '../../common/helpers/formsHelper';
import { POSTER_APP_NAME } from '../constants';
import { SignageValues } from '../types/templates';
import { getPosterService } from '.';
import { ICartStateService, IGlobalAttributesService, ISignagesService } from './types';

export const GlobalAttributeEvents = {
  SET_GLOBAL_VALUE: `${POSTER_APP_NAME}/setGlobalValue`,
};

/**
 * Separator for combo value
 */
const COMBO_VALUE_SEPARATOR = ',';

export class GlobalAttributesService
  implements IGlobalAttributesService, PiivoApplicationLifecycleService
{
  private state: {
    globalValues: Record<string, AttributeValues.AttributeValue>;
    /**
     * If should apply global values to the rest of the app's form on value change
     */
    applyGlobalValuesToApp: boolean;
  } = Vue.observable({
    globalValues: {},
    applyGlobalValuesToApp: false,
  });

  /**
   * Unregisters router hook
   */
  unregisterRouterHook: (() => void) | null = null;

  /**
   * @param store - vuex store
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-useless-constructor
  constructor(private readonly store: Store<any>, private readonly router: VueRouter) {}

  /**
   * Callback for POSter app mount
   */
  public onApplicationMount(): void {
    this.initGlobalValues();
    this.unregisterRouterHook = this.router.afterEach(() => {
      this.importUrlValues();
    });
  }

  /**
   * Callback for POSter app before destroyed
   */
  public onBeforeApplicationDestroyed(): void {
    this.resetData();
    this.unregisterRouterHook?.();
    this.unregisterRouterHook = null;
  }

  /**
   * @inheritdoc
   */
  public getForm(): Form.Form | null {
    return services.getService<IApplicationsService>('applications').getAppForm(POSTER_APP_NAME);
  }

  /**
   * @inheritdoc
   */
  public setGlobalValue(attribute: Form.Attribute, value: AttributeValues.AttributeValue): void {
    Vue.set(this.state.globalValues, attribute.alias, value);

    // Emit cloned value to prevent external editing of global attrs
    const clonedValue = JSON.parse(JSON.stringify(value)) as AttributeValues.AttributeValue;

    if (this.state.applyGlobalValuesToApp) {
      const cart = getPosterService<ICartStateService>('cartState').get();
      for (const poster of cart) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        getPosterService<ISignagesService>('signages').replacePosterValue(
          poster,
          attribute.alias,
          clonedValue
        );
      }

      coreExtension.eventBus.emit(GlobalAttributeEvents.SET_GLOBAL_VALUE, {
        attribute,
        value: clonedValue,
      });
    }
  }

  /**
   * @inheritdoc
   */
  public getGlobalValue(attribute: Form.Attribute): AttributeValues.AttributeValue | undefined {
    return this.state.globalValues[attribute.alias];
  }

  /**
   * @inheritdoc
   */
  public getGlobalValues(): Record<string, AttributeValues.AttributeValue> {
    // Return cloned values to prevent external editing of global attrs
    return JSON.parse(JSON.stringify(this.state.globalValues)) as Record<
      string,
      AttributeValues.AttributeValue
    >;
  }

  /**
   * Initializes global values from user extended properties and url values
   */
  private initGlobalValues(): void {
    const form = this.getForm();
    if (!form) {
      return;
    }

    // Disable global values application while initializing
    this.state.applyGlobalValuesToApp = false;

    // Grab user extended properties
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const extendedProperties = this.store.getters.user.extendedProperties as SignageValues;
    // Clone user props to prevent edit global values > edit ext props
    const clonedExtendedProperties: SignageValues = extendedProperties
      ? (JSON.parse(JSON.stringify(extendedProperties)) as SignageValues)
      : {};

    // Grab url values
    const urlValues = queryToFormValues(this.router.currentRoute.query, form.attributeGroups);

    // Apply the extendedProperties and then url values in that order
    this.saveToGlobalValues({ ...clonedExtendedProperties, ...urlValues });

    // Enable global values application
    this.state.applyGlobalValuesToApp = true;
  }

  /**
   * Applies the url values to the global values
   */
  private importUrlValues(): void {
    const form = this.getForm();
    if (!form) {
      return;
    }

    const urlValues = queryToFormValues(this.router.currentRoute.query, form.attributeGroups);

    this.saveToGlobalValues(urlValues);
  }

  /**
   * Applies values to the global values
   *
   * @param inputValues - the values to apply
   */
  private saveToGlobalValues(inputValues: Record<string, AttributeValues.AttributeValue>): void {
    const form = this.getForm();
    if (!form) {
      return;
    }

    const globalAttributesMap: Record<string, Form.Attribute> = {};
    iterateForm(form, (attr) => {
      globalAttributesMap[attr.alias] = attr;
    });

    for (const alias in inputValues) {
      const attribute = globalAttributesMap[alias];
      if (!attribute) {
        continue;
      }

      let value = inputValues[alias];
      if (
        attribute.type === AttributeTypes.LINKS &&
        attribute.options?.displayMode === DisplayMode.Combo &&
        typeof value === 'string'
      ) {
        value = value.split(COMBO_VALUE_SEPARATOR);
      }

      this.setGlobalValue(attribute, value);
    }
  }

  /**
   * Resets global attributes data
   */
  private resetData(): void {
    this.state.globalValues = {};
    this.state.applyGlobalValuesToApp = false;
  }
}
